import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Clientes = () => {
    const [clientes, setClientes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentCliente, setCurrentCliente] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/login');
        }
    }, [navigate]);

    const fetchClientes = async () => {
        try {
            const response = await axios.get('https://gavilanes.com.mx/SCRV/clientes/clientes.php');
            setClientes(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    useEffect(() => {
        fetchClientes();
    }, []);

    const toggleModal = (cliente = null) => {
        setCurrentCliente(cliente);
        setShowModal(!showModal);
    };

    const cambiarEstado = async (cliente) => {
        const nuevoEstado = cliente.estatus === '1' ? '0' : '1';
        try {
            await axios.post('https://gavilanes.com.mx/SCRV/clientes/cambiarEstado.php', {
                id_cliente: cliente.id_cliente,
                estatus: nuevoEstado,
            });

            setClientes(clientes.map((c) =>
                c.id_cliente === cliente.id_cliente ? { ...c, estatus: nuevoEstado } : c
            ));
        } catch (error) {
            console.error('Error updating client status:', error);
        }
    };

    const abrirMapa = (direccion) => {
        const direccionUrl = direccion.split(' ').join('+');
        window.open(`https://www.google.com/maps/search/?api=1&query=${direccionUrl}`, '_blank');
    };

    const handleInputChange = (e) => {
        setCurrentCliente({ ...currentCliente, [e.target.name]: e.target.value });
    };

    const actualizarCliente = async () => {
        try {
            const endpoint =
                currentCliente.tipo_cliente === 'detalle'
                    ? 'actualizarClienteDetalle.php'
                    : 'actualizarCliente.php';

            await axios.post(`https://gavilanes.com.mx/SCRV/clientes/${endpoint}`, {
                ...currentCliente,
            });

            setShowModal(false);
            fetchClientes();
        } catch (error) {
            console.error('Error updating client:', error);
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Clientes Registrados</h2>
            <button style={styles.addButton} onClick={() => navigate('/registroClientes')}>
                Registrar Nuevo Cliente
            </button>
            <div style={styles.cardContainer}>
                {clientes.map((cliente) => (
                    <div key={cliente.id_cliente} style={styles.card}>
                        <h3 style={styles.clienteName}>
                            {cliente.tipo_cliente === 'detalle' && cliente.razon_social
                                ? cliente.razon_social
                                : cliente.cliente}
                        </h3>
                        <p
                            style={
                                cliente.estatus === '1' ? styles.activeStatus : styles.inactiveStatus
                            }
                        >
                            {cliente.estatus === '1' ? 'Activo' : 'Inactivo'}
                        </p>
                        <div style={styles.actions}>
                            <button style={styles.editButton} onClick={() => toggleModal(cliente)}>
                                Modificar
                            </button>
                            <button
                                style={styles.statusButton}
                                onClick={() => cambiarEstado(cliente)}
                            >
                                {cliente.estatus === '1' ? 'Dar de Baja' : 'Dar de Alta'}
                            </button>
                            <button
                                style={styles.mapButton}
                                onClick={() => abrirMapa(cliente.direccion)}
                            >
                                <FaMapMarkerAlt style={styles.mapIcon} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {showModal && (
    <div style={styles.modalOverlay}>
        <div style={styles.modalContent}>
            <h2>Modificar Cliente</h2>
            {currentCliente.tipo_cliente === 'conveniencia' ? (
                // Campos para clientes de conveniencia
                <>
                    <input
                        style={styles.input}
                        type="text"
                        name="cliente"
                        value={currentCliente.cliente}
                        onChange={handleInputChange}
                        placeholder="Nombre del Cliente"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="telefono"
                        value={currentCliente.telefono}
                        onChange={handleInputChange}
                        placeholder="Teléfono"
                    />
                    <input
                        style={styles.input}
                        type="email"
                        name="correo"
                        value={currentCliente.correo}
                        onChange={handleInputChange}
                        placeholder="Correo"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="sucursal_matriz"
                        value={currentCliente.sucursal_matriz}
                        onChange={handleInputChange}
                        placeholder="Sucursal Matriz"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="direccion"
                        value={currentCliente.direccion}
                        onChange={handleInputChange}
                        placeholder="Dirección"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="categoria"
                        value={currentCliente.categoria}
                        onChange={handleInputChange}
                        placeholder="Categoría"
                    />
                </>
            ) : (
                // Campos para clientes de detalle
                <>
                    <input
                        style={styles.input}
                        type="text"
                        name="telefono"
                        value={currentCliente.telefono}
                        onChange={handleInputChange}
                        placeholder="Teléfono"
                    />
                    <input
                        style={styles.input}
                        type="email"
                        name="correo"
                        value={currentCliente.correo}
                        onChange={handleInputChange}
                        placeholder="Correo"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="categoria"
                        value={currentCliente.categoria}
                        onChange={handleInputChange}
                        placeholder="Categoría"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="razon_social"
                        value={currentCliente.razon_social}
                        onChange={handleInputChange}
                        placeholder="Razón Social"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="nombre_colonia"
                        value={currentCliente.nombre_colonia}
                        onChange={handleInputChange}
                        placeholder="Nombre de Colonia"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="codigo_postal"
                        value={currentCliente.codigo_postal}
                        onChange={handleInputChange}
                        placeholder="Código Postal"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="calle"
                        value={currentCliente.calle}
                        onChange={handleInputChange}
                        placeholder="Calle"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="numero"
                        value={currentCliente.numero}
                        onChange={handleInputChange}
                        placeholder="Número"
                    />
                    <input
                        style={styles.input}
                        type="text"
                        name="referencia"
                        value={currentCliente.referencia}
                        onChange={handleInputChange}
                        placeholder="Referencia"
                    />
                </>
            )}
            <div style={styles.modalActions}>
                <button style={styles.saveButton} onClick={actualizarCliente}>
                    Guardar
                </button>
                <button style={styles.cancelButton} onClick={() => toggleModal()}>
                    Cancelar
                </button>
            </div>
        </div>
    </div>
)}

        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#f4f5f7',
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        maxWidth: '1200px',
        margin: '20px auto',
    },
    title: {
        fontSize: '32px',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '30px',
        textAlign: 'center',
    },
    addButton: {
        backgroundColor: '#3498db',
        color: 'white',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '30px',
        cursor: 'pointer',
        fontSize: '16px',
        display: 'block',
        margin: '0 auto 40px auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 0.3s ease',
    },
    addButtonHover: {
        backgroundColor: '#2980b9',
    },
    cardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '20px',
    },
    card: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
    },
    cardHover: {
        transform: 'translateY(-5px)',
    },
    clienteName: {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '10px',
    },
    activeStatus: {
        color: '#27ae60',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    inactiveStatus: {
        color: '#e74c3c',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    editButton: {
        backgroundColor: '#f39c12',
        color: 'white',
        padding: '8px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },
    statusButton: {
        backgroundColor: '#e74c3c',
        color: 'white',
        padding: '8px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },
    modalOverlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
        width: '400px',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    saveButton: {
        backgroundColor: '#2ecc71',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    cancelButton: {
        backgroundColor: '#e74c3c',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    mapButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    mapIcon: {
        color: '#3498db',
        fontSize: '20px',
    },
};

export default Clientes;

import { useNavigate } from 'react-router-dom';
import React, {useEffect } from 'react';


const Footer = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <footer style={styles.footerStyle}>
            <p style={styles.textStyle}>
                © 2024 ALGB Alimentos México S.A. de C.V. Todos los derechos reservados.
            </p>
        </footer>
    );
};

const styles = {
    footerStyle: {
        backgroundColor: '#324259',
        color: 'white',
        padding: '20px 0',
        textAlign: 'center',
        position: 'relative',
        bottom: '0',
        width: '100%',
        marginTop: 'auto',
        boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1)',
    },
    textStyle: {
        margin: '0',
        fontSize: '14px',
    }
};

export default Footer;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [correo, setCorreo] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'SCRV'; // Cambia el título de la página a "SCRV"
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://gavilanes.com.mx/SCRV/login.php', { correo, contrasena });
            if (response.data.status === 'success') {
                // Guarda los datos del usuario en el almacenamiento local y navega a la página principal
                localStorage.setItem('user', JSON.stringify(response.data.user));
                navigate('/');
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError('Error al iniciar sesión. Intente de nuevo.');
            console.error(error);
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.mainTitle}>SCRV</h1> {/* Título principal "SCRV" */}
            <h2 style={styles.title}>Iniciar Sesión</h2>
            <form onSubmit={handleLogin} style={styles.form}>
                {error && <p style={styles.error}>{error}</p>}
                <input
                    type="email"
                    placeholder="Correo"
                    value={correo}
                    onChange={(e) => setCorreo(e.target.value)}
                    style={styles.input}
                    required
                />
                <input
                    type="password"
                    placeholder="Contraseña"
                    value={contrasena}
                    onChange={(e) => setContrasena(e.target.value)}
                    style={styles.input}
                    required
                />
                <button type="submit" style={styles.loginButton}>Entrar</button>
            </form>
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#f4f5f7',
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        margin: '100px auto',
        textAlign: 'center',
    },
    mainTitle: { // Estilo para el texto "SCRV"
        fontSize: '36px',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '10px',
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '20px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        padding: '10px',
        marginBottom: '15px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        fontSize: '16px',
    },
    loginButton: {
        backgroundColor: '#3498db',
        color: 'white',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    error: {
        color: '#e74c3c',
        marginBottom: '10px',
    },
};

export default Login;

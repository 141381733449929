import React, {useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../images/logo-gavilanes.png';

const Navegacion = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/login');
        }
    }, [navigate]);
    const handleLogout = () => {
        localStorage.removeItem('user'); // Elimina los datos del usuario del localStorage
        navigate('/login'); // Redirige a la página de inicio de sesión
    };

    return (
        <nav style={styles.navbarStyle}>
            <div>
                <Link to="/">
                    <img style={styles.logoStyle} src={logo} alt="Logo" />
                </Link>
            </div>
            <ul style={styles.navLinksStyle}>
                <li>
                    <Link to="/rutas" style={styles.linkStyle}>
                        <button style={styles.buttonStyle}>Rutas</button>
                    </Link>
                </li>
                <li>
                    <Link to="/RegistrarVenta" style={styles.linkStyle}>
                        <button style={styles.buttonStyle}>Registrar Venta</button>
                    </Link>
                </li>
                <li>
                    <Link to="/clientes" style={styles.linkStyle}>
                        <button style={styles.buttonStyle}>Clientes</button>
                    </Link>
                </li>
                <li>
                    <Link to="/productos" style={styles.linkStyle}>
                        <button style={styles.buttonStyle}>Productos</button>
                    </Link>
                </li>
                <li>
                    <button onClick={handleLogout} style={styles.buttonStyle}>Cerrar Sesión</button>
                </li>
            </ul>
        </nav>
    );
};

const styles = {
    navbarStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 40px',
        backgroundColor: '#324259',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    navLinksStyle: {
        listStyle: 'none',
        display: 'flex',
        gap: '20px',
        margin: '0',
    },
    buttonStyle: {
        backgroundColor: '#1abc9c',
        border: 'none',
        color: 'white',
        padding: '12px 25px',
        cursor: 'pointer',
        borderRadius: '20px',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    logoStyle: {
        height: '60px',
    },
    linkStyle: {
        textDecoration: 'none',
    }
};

export default Navegacion;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './vistas/navegacion';
import Charts from './graficas/charts';
import Clientes from './vistas/clientes/clientes';
import RegistroCliente from './vistas/clientes/registroCliente';
import Productos from './vistas/productos/productos';
import DetalleProducto from './vistas/productos/DetalleProducto';
import RegistrarVenta from './vistas/ventas/registrar-venta';
import Rutas from './vistas/rutas/rutas';
import DetalleRuta from './vistas/rutas/detalleRuta';
import Login from './login';
import Footer from './vistas/footer';

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

function AppContent() {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';

    return (
        <div style={styles.appStyle}>
            {!isLoginPage && <Navbar />}
            <main style={styles.mainStyle}>
                <Routes>
                    <Route path="/" element={<Charts />} />
                    <Route path="/clientes" element={<Clientes />} />
                    <Route path="/productos" element={<Productos />} />
                    <Route path="/registroClientes" element={<RegistroCliente />} />
                    <Route path="/registrarVenta" element={<RegistrarVenta />} />
                    <Route path="/producto/:id" element={<DetalleProducto />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/rutas" element={<Rutas />} />
                    <Route path="/detalleRuta/:id" element={<DetalleRuta />} />
                </Routes>
            </main>
            {!isLoginPage && <Footer />}
        </div>
    );
}

const styles = {
    appStyle: {
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f5f5f5',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    mainStyle: {
        padding: '20px',
        flexGrow: 1,
    }
};

export default App;

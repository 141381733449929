import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root');

const RegistrarVenta = () => {
    const [clientes, setClientes] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [productos, setProductos] = useState([]);
    
    const [filtroClientes, setFiltroClientes] = useState('');
    const [filtroSucursales, setFiltroSucursales] = useState('');
    const [filtroProductos, setFiltroProductos] = useState('');
    
    const [ventaProductos, setVentaProductos] = useState([]);
    const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState(null);
    const [metodoPago, setMetodoPago] = useState('Contado');
    const [total, setTotal] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchClientes = async () => {
            const response = await axios.get('https://gavilanes.com.mx/SCRV/clientes/obtener_clientes.php');
            setClientes(response.data);
        };

        const fetchSucursales = async () => {
            const response = await axios.get('https://gavilanes.com.mx/SCRV/sucursales/obtener_sucursales.php');
            setSucursales(response.data);
        };

        const fetchProductos = async () => {
            const response = await axios.get('https://gavilanes.com.mx/SCRV/productos/obtener_productos.php?venta=1');
            setProductos(response.data);
        };
        
        fetchClientes();
        fetchSucursales();
        fetchProductos();
    }, []);

    const agregarProducto = async (producto) => {
        try {
            // Realizar una solicitud para obtener el precio del producto específico
            const response = await axios.get(`https://gavilanes.com.mx/SCRV/precios/obtener_precios.php?id_producto=${producto.id_producto}`);
            const precio = response.data.precio_publico || 0;
    
            setVentaProductos(prev => {
                const productoExistente = prev.find(p => p.id_producto === producto.id_producto);
                if (productoExistente) {
                    return prev.map(p =>
                        p.id_producto === producto.id_producto ? { ...p, cantidad: p.cantidad + 1 } : p
                    );
                }
                return [...prev, { ...producto, cantidad: 1, precio_unitario: precio }];
            });
        } catch (error) {
            alert("Error al obtener el precio del producto: " + error.message);
        }
    };
    

    const actualizarCantidad = (id_producto, cantidad) => {
        if (cantidad >= 1) {
            setVentaProductos(prev =>
                prev.map(p => (p.id_producto === id_producto ? { ...p, cantidad } : p))
            );
        }
    };
    

    const eliminarProducto = (id_producto) => {
        setVentaProductos(prev => prev.filter(p => p.id_producto !== id_producto));
    };

    useEffect(() => {
        const nuevoTotal = ventaProductos.reduce((acc, p) => acc + (p.precio_unitario * p.cantidad), 0);
        setTotal(nuevoTotal);
    }, [ventaProductos]);
    

    const registrarVenta = async () => {
        const ventaData = {
            total,
            total_piezas: ventaProductos.reduce((acc, p) => acc + p.cantidad, 0),
            metodo_pago: metodoPago,
            id_cliente: clienteSeleccionado?.id_cliente,
            id_usuario: 1,  // Id del usuario que esté registrado
            detalles: ventaProductos.map(producto => ({
                id_producto: producto.id_producto,
                cantidad: producto.cantidad,
                precio_unitario: producto.precio_unitario
            }))
        };

        try {
            const response = await axios.post('https://gavilanes.com.mx/SCRV/ventas/registrar_venta.php', ventaData);
            alert("Venta registrada con éxito");
            setVentaProductos([]);
            setTotal(0);
            setClienteSeleccionado(null);
            setSucursalSeleccionada(null);
        } catch (error) {
            alert("Error al registrar la venta: " + error.message);
        }
    };

    const seleccionarProductoPorCodigo = () => {
        const producto = productos.find(p => p.id_producto === filtroProductos);
        if (producto) {
            agregarProducto(producto);
            setFiltroProductos('');
        } else {
            alert("Producto no encontrado");
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Registrar venta</h2>

            {/* Selección de Cliente */}
            <div style={styles.section}>
                <h3>Seleccione el cliente</h3>
                <input
                    type="text"
                    placeholder="Buscar cliente por nombre"
                    value={filtroClientes}
                    onChange={(e) => setFiltroClientes(e.target.value)}
                    style={styles.input}
                />
                {filtroClientes && clientes.filter(cliente => cliente.cliente.toLowerCase().includes(filtroClientes.toLowerCase()))
                    .map(cliente => (
                        <p
                            key={cliente.id_cliente}
                            style={styles.option}
                            onClick={() => {
                                setClienteSeleccionado(cliente);
                                setFiltroClientes('');
                            }}
                        >
                            {cliente.cliente}
                        </p>
                    ))
                }
                {clienteSeleccionado && <p>Cliente seleccionado: {clienteSeleccionado.cliente}</p>}
            </div>

            {/* Selección de Sucursal */}
            <div style={styles.section}>
                <h3>Seleccione la sucursal</h3>
                <input
                    type="text"
                    placeholder="Buscar sucursal..."
                    value={filtroSucursales}
                    onChange={(e) => setFiltroSucursales(e.target.value)}
                    style={styles.input}
                />
                {filtroSucursales && sucursales.filter(sucursal => sucursal.razon_social.toLowerCase().includes(filtroSucursales.toLowerCase()))
                    .map(sucursal => (
                        <p
                            key={sucursal.id_sucursal}
                            style={styles.option}
                            onClick={() => {
                                setSucursalSeleccionada(sucursal);
                                setFiltroSucursales('');
                            }}
                        >
                            {sucursal.razon_social}
                        </p>
                    ))
                }
                {sucursalSeleccionada && <p>Sucursal seleccionada: {sucursalSeleccionada.razon_social}</p>}
            </div>

            {/* Catálogo de Productos */}
            <div style={styles.section}>
                <h3>Seleccionar productos</h3>
                <input
                    type="text"
                    placeholder="Escribir código de producto"
                    value={filtroProductos}
                    onChange={(e) => setFiltroProductos(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && seleccionarProductoPorCodigo()}
                    style={styles.input}
                />
                <button onClick={() => setIsModalOpen(true)} style={styles.button}>Abrir Catálogo</button>
            </div>

            <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={styles.modal}>
                <button onClick={() => setIsModalOpen(false)} style={styles.closeButton}>Cerrar</button>
                <h2>Catálogo de Productos</h2>
                <input
                    type="text"
                    placeholder="Buscar producto por nombre"
                    value={filtroProductos}
                    onChange={(e) => setFiltroProductos(e.target.value)}
                    style={styles.input}
                />
                {productos.filter(producto => producto.nombre_producto.toLowerCase().includes(filtroProductos.toLowerCase()))
                    .map(producto => (
                        <div key={producto.id_producto} style={styles.productItem}>
                            <span>{producto.nombre_producto}</span>
                            <button onClick={() => agregarProducto(producto)}>Agregar</button>
                        </div>
                    ))
                }
                
            </Modal>

            {/* Lista de Productos Seleccionados */}
            <div style={styles.section}>
                <h3>Productos Seleccionados</h3>
                {ventaProductos.map(producto => (
                    <div key={producto.id_producto} style={styles.productItem}>
                        <span>{producto.nombre_producto}</span>
                        <input
                            type="number"
                            value={producto.cantidad}
                            onChange={(e) => actualizarCantidad(producto.id_producto, parseInt(e.target.value))}
                            style={styles.quantityInput}
                        />
                        <button onClick={() => eliminarProducto(producto.id_producto)} style={styles.deleteButton}>🗑️</button>
                    </div>
                ))}
            </div>

            {/* Método de Pago */}
            <div style={styles.section}>
                <h3>Método de pago</h3>
                <label>
                    <input
                        type="radio"
                        name="metodoPago"
                        checked={metodoPago === 'Contado'}
                        onChange={() => setMetodoPago('Contado')}
                    />
                    Contado
                </label>
                <label>
                    <input
                        type="radio"
                        name="metodoPago"
                        checked={metodoPago === 'Crédito'}
                        onChange={() => setMetodoPago('Crédito')}
                    />
                    Crédito
                </label>
            </div>

            {/* Total */}
            <div style={styles.section}>
                <h3>Total: ${total.toFixed(2)}</h3>
                <button onClick={registrarVenta} style={styles.registerButton}>Registrar venta</button>
            </div>
        </div>
    );
};

const styles = {
    container: { 
        maxWidth: '600px', 
        margin: 'auto', 
        padding: '20px', 
        backgroundColor: '#f4f4f9', 
        borderRadius: '8px', 
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' 
    },
    
    header: { 
        textAlign: 'center', 
        color: '#333', 
        fontSize: '24px', 
        fontWeight: '600', 
        marginBottom: '20px' 
    },
    section: { 
        marginBottom: '20px', 
        padding: '10px', 
        backgroundColor: '#fff', 
        borderRadius: '8px', 
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' 
    },
    input: { 
        padding: '10px', 
        width: '95%', 
        marginBottom: '10px', 
        borderRadius: '6px', 
        border: '1px solid #ddd', 
        fontSize: '14px' 
    },
    option: { 
        padding: '8px 12px', 
        cursor: 'pointer', 
        backgroundColor: '#f9f9f9', 
        borderRadius: '4px', 
        marginBottom: '5px', 
        transition: 'background-color 0.3s', 
        fontSize: '14px' 
    },
    button: { 
        padding: '10px 15px', 
        backgroundColor: '#007bff', 
        color: 'white', 
        borderRadius: '6px', 
        cursor: 'pointer', 
        fontWeight: '500', 
        transition: 'background-color 0.3s', 
        border: 'none' 
    },
    closeButton: { 
        padding: '10px 15px', 
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#dc3545', 
        color: 'white', 
        borderRadius: '6px', 
        cursor: 'pointer', 
        fontWeight: '500', 
        border: 'none' 
    },
    modal: { 
        content: { 
            padding: '20px', 
            maxWidth: '500px', 
            margin: 'auto', 
            backgroundColor: '#fff', 
            borderRadius: '8px', 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)' 
        } 
    },
    productItem: { 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: '8px', 
        backgroundColor: '#f7f7f9', 
        borderRadius: '6px', 
        marginBottom: '10px' 
    },
    quantityInput: { 
        width: '50px', 
        padding: '5px', 
        textAlign: 'center', 
        borderRadius: '4px', 
        border: '1px solid #ddd' 
    },
    deleteButton: { 
        backgroundColor: '#dc3545', 
        color: 'white', 
        padding: '5px 10px', 
        borderRadius: '4px', 
        cursor: 'pointer', 
        fontWeight: '500', 
        border: 'none' 
    },
    registerButton: { 
        width: '100%', 
        padding: '15px', 
        backgroundColor: '#28a745', 
        color: 'white', 
        borderRadius: '8px', 
        cursor: 'pointer', 
        fontWeight: '600', 
        border: 'none', 
        fontSize: '16px', 
        transition: 'background-color 0.3s' 
    }
};


export default RegistrarVenta;

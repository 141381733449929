import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const DetallesRuta = () => {
  const { id } = useParams(); // Obtener el ID de la ruta desde la URL
  const [detalles, setDetalles] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para manejar el modal
  const [tipoCliente, setTipoCliente] = useState("");
  const [razonesSociales, setRazonesSociales] = useState([]);
  const [formConveniencia, setFormConveniencia] = useState({
    razonSocial: "",
    colonia: "",
    codigoPostal: "",
    calle: "",
    numero: "",
    referencia: "",
    nombreCliente: "",
  });

  // Fetch de los detalles de la ruta
  const fetchDetallesRuta = async () => {
    try {
      const response = await fetch(
        `https://gavilanes.com.mx/SCRV/rutas/detalle_ruta.php?id=${id}`
      );
      const data = await response.json();
      setDetalles(data);
    } catch (error) {
      console.error("Error al obtener detalles de la ruta:", error);
    }
  };

  useEffect(() => {
    fetchDetallesRuta();
  }, [id]);

  const closeModal = () => {
    setIsModalOpen(false);
    setTipoCliente("");
    setRazonesSociales([]);
    setFormConveniencia({
      razonSocial: "",
      colonia: "",
      codigoPostal: "",
      calle: "",
      numero: "",
      referencia: "",
      nombreCliente: "",
    });
  };

  const handleAgregarDireccion = () => {
    setIsModalOpen(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormConveniencia((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  if (!detalles) {
    return <div style={styles.loading}>Cargando detalles...</div>;
  }

  const handleTipoClienteClick = async (tipo) => {
    setTipoCliente(tipo);
    if (tipo === "detalle") {
      try {
        const response = await fetch("https://gavilanes.com.mx/SCRV/rutas/razon_social.php");
        const data = await response.json();
        const razonesFormateadas = data.map((razon) => ({
          id_sucursal: razon.id_sucursal,
          razon_social: razon.razon_social,
        }));
        setRazonesSociales(razonesFormateadas);
      } catch (error) {
        console.error("Error al obtener razones sociales:", error);
      }
    } else if (tipo === "conveniencia") {
      try {
        const response = await fetch("https://gavilanes.com.mx/SCRV/clientes/obtener_clientes.php");
        const data = await response.json();
        setRazonesSociales([]); // Limpiar razones sociales cuando es "conveniencia"
      } catch (error) {
        console.error("Error al obtener clientes:", error);
      }
    }
  };
  
  const handleRegistrarDireccion = async () => {
    if (!tipoCliente) {
      alert("Por favor selecciona el tipo de cliente.");
      return;
    }
  
    let requestBody = {
      idRuta: id, // Agregar el ID de la ruta
      tipoRuta: "",
      idSucursal: null,
      datosConveniencia: null,
    };
  
    if (tipoCliente === "detalle") {
      if (!formConveniencia.razonSocial) {
        alert("Selecciona una tienda para tipo detalle.");
        return;
      }
      requestBody.tipoRuta = "detalle";
      requestBody.idSucursal = formConveniencia.razonSocial;
    } else if (tipoCliente === "conveniencia") {
      const { razonSocial, colonia, codigoPostal, calle, numero, referencia, nombreCliente } = formConveniencia;
  
      if (!razonSocial || !colonia || !codigoPostal || !calle || !numero || !nombreCliente) {
        alert("Por favor completa todos los campos obligatorios para conveniencia.");
        return;
      }
  
      requestBody.tipoRuta = "conveniencia";
      requestBody.datosConveniencia = {
        razonSocial,
        colonia,
        codigoPostal,
        calle,
        numero,
        referencia,
        nombreCliente,
      };
    }
  
    try {
      const response = await fetch("https://gavilanes.com.mx/SCRV/rutas/agregar_direccion.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          closeModal();
          fetchDetallesRuta(); // Actualizar lista de detalles.
        } else {
          alert(result.error || "Error al registrar la dirección.");
        }
      } else {
        console.error("Error al guardar dirección:", response.statusText);
      }
    } catch (error) {
      console.error("Error de conexión:", error);
    }
  };  
  

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Ruta: {detalles.nombre_ruta || "Sin nombre disponible"}</h1>
      <div style={styles.header}>
        <h3>
          ID Ruta: <strong>{detalles.id}</strong>
        </h3>
        {/* Botón para abrir el modal */}
        <button style={styles.addButton} onClick={handleAgregarDireccion}>
          Agregar Dirección
        </button>
      </div>

      <div style={styles.tableContainer}>
        <h2 style={styles.subtitle}>Sucursales Asociadas</h2>
        <table style={styles.table}>
          <thead>
            <tr>
              <th>Tipo de Servicio</th>
              <th>Cliente</th>
              <th>Tienda</th>
              <th>Colonia</th>
              <th>Calle</th>
              <th>Número</th>
              <th>Referencia</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {detalles.sucursales.map((sucursal, index) => (
              <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                <td>{sucursal.cliente || sucursal.tipo_cliente || "Sin dato"}</td>
                <td>{sucursal.id_cliente || "N/A"}</td>
                <td>{sucursal.razon_social || "Sin tienda"}</td>
                <td>{sucursal.nombre_colonia || "Sin colonia"}</td>
                <td>{sucursal.calle || "Sin calle"}</td>
                <td>{sucursal.numero || "Sin número"}</td>
                <td>{sucursal.referencia || "Sin referencia"}</td>
                <td style={styles.actions}>
                  <button style={styles.editButton}>✏️</button>
                  <button style={styles.deleteButton}>🗑️</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button style={styles.downloadButton}>Descargar PDF</button>

      {/* Modal */}
      {isModalOpen && (
      <div style={styles.modalOverlay}>
        <div style={styles.modal}>
          <h3 style={styles.modalTitle}>Selecciona el tipo de cliente</h3>
          <div style={styles.modalButtons}>
            <button
              style={{
                ...styles.modalButton,
                ...(tipoCliente === "detalle" ? styles.selectedButton : {}),
              }}
              onClick={() => handleTipoClienteClick("detalle")}
            >
              Detalle
            </button>
            <button
              style={{
                ...styles.modalButton,
                ...(tipoCliente === "conveniencia" ? styles.selectedButton : {}),
              }}
              onClick={() => handleTipoClienteClick("conveniencia")}
            >
              Conveniencia
            </button>
          </div>
          {tipoCliente === "detalle" && (
            <div style={styles.selectContainer}>
              <h4>Tiendas:</h4>
              <select
                style={styles.select}
                value={formConveniencia.razonSocial}
                onChange={(e) => setFormConveniencia({ ...formConveniencia, razonSocial: e.target.value })}
              >
                <option value="">Selecciona una tienda</option>
                {razonesSociales.map((razon) => (
                  <option key={razon.id_sucursal} value={razon.id_sucursal}>
                    {razon.razon_social}
                  </option>
                ))}
              </select>
            </div>
          )}
          {tipoCliente === "conveniencia" && (
            <form style={styles.form}>
              {["razonSocial", "colonia", "codigoPostal", "calle", "numero", "referencia", "nombreCliente"].map(
                (field) => (
                  <div key={field} style={styles.inputContainer}>
                    <label style={styles.label}>{field}</label>
                    <input
                      type="text"
                      name={field}
                      value={formConveniencia[field]}
                      onChange={handleFormChange}
                      style={styles.input}
                    />
                  </div>
                )
              )}
            </form>
          )}
          <div style={styles.modalActions}>
            <button style={styles.registerButton} onClick={handleRegistrarDireccion}>
              Guardar
            </button>
            <button style={styles.cancelButton} onClick={closeModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    )}
    </div>
  );
};

const styles = {
    container: {
        padding: "30px",
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: "1200px",
        margin: "30px auto",
    },
      title: {
        textAlign: "center",
        marginBottom: "20px",
        fontSize: "28px",
        color: "#333",
      },
      header: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "15px",
        paddingBottom: "10px",
        borderBottom: "2px solid #f1f1f1",
      },
      tableContainer: {
        marginTop: "20px",
        backgroundColor: "#f9f9f9",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      },
      subtitle: {
        fontSize: "22px",
        marginBottom: "15px",
        color: "#444",
        fontWeight: "600",
      },
      table: {
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: "20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
      },
      th: {
        backgroundColor: "#f4f4f4",
        padding: "12px",
        textAlign: "center", // Centrado horizontalmente
        verticalAlign: "middle", // Centrado verticalmente
        fontWeight: "600",
        color: "#333",
        borderBottom: "1px solid #ddd",
      },
      td: {
        padding: "12px",
        textAlign: "center", // Centrado horizontalmente
        verticalAlign: "middle", // Centrado verticalmente
        fontSize: "14px",
        color: "#666",
        borderBottom: "1px solid #ddd",
      },
      actions: {
        display: "flex",
        justifyContent: "center",
      },
      editButton: {
        backgroundColor: "#FFD700",
        border: "none",
        borderRadius: "4px",
        padding: "8px 12px",
        cursor: "pointer",
        marginRight: "8px",
        fontSize: "16px",
        color: "#fff",
      },
      deleteButton: {
        backgroundColor: "#FF6347",
        border: "none",
        borderRadius: "4px",
        padding: "8px 12px",
        cursor: "pointer",
        fontSize: "16px",
        color: "#fff",
      },
      downloadButton: {
        display: "block",
        margin: "30px auto 0",
        backgroundColor: "#007BFF",
        color: "#fff",
        border: "none",
        borderRadius: "6px",
        padding: "12px 25px",
        fontSize: "18px",
        cursor: "pointer",
        textAlign: "center",
      },
      loading: {
        textAlign: "center",
        fontSize: "20px",
        color: "#888",
      },
      evenRow: {
        backgroundColor: "#fafafa",
      },
      oddRow: {
        backgroundColor: "#fff",
      },
  addButton: {
    marginTop: "10px",
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
},
modal: {
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "10px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
},
modalTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "15px",
    color: "#333",
},
inputContainer: {
    marginBottom: "10px",
    textAlign: "left",
},
label: {
    display: "block",
    textAlign: "left",
    fontSize: "16px",
    marginBottom: "5px",
},
input: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    textAlign: "left",
},

    modalButtons: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        marginBottom: "20px",
    },
    modalButton: {
        padding: "10px 20px",
        fontSize: "16px",
        borderRadius: "5px",
        border: "1px solid #ddd",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
    },
    selectedButton: {
        backgroundColor: "#28a745",
        color: "#fff",
        border: "none",
    },
    selectContainer: {
        marginBottom: "20px",
        textAlign: "left",
    },
    select: {
        width: "100%",
        padding: "10px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        fontSize: "16px",
        marginTop: "10px",
    },
    modalActions: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    registerButton: {
        padding: "10px 20px",
        backgroundColor: "#28a745",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    cancelButton: {
        padding: "10px 20px",
        backgroundColor: "#f44336",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    
    cancelButtonHover: {
        backgroundColor: "#d32f2f",
    },
};

export default DetallesRuta;
